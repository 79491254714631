import {Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem} from "@mui/material";
import React, {useState} from "react";
import {Logout, PersonAdd, Settings} from "@mui/icons-material";
import {logout} from "../../redux/features/userSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import CreateUser from "../user/create-user";
import UserModal from "../modals/user.modal";

export default function UserMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openUserModal, setOpenUserModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onProfileHandler = async () => {
    navigate('/profile');
    setAnchorEl(null);
  }

  const onAccountHandler = async () => {
    navigate('/account');
    setAnchorEl(null);
  }

  const onLogoutHandler = async () => {
    dispatch(logout());
    navigate('/login');
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={onProfileHandler}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={onAccountHandler}>
          <Avatar /> Account
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setOpenUserModal(true)}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another user
        </MenuItem>
        {/*<MenuItem onClick={handleClose}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <Settings fontSize="small" />*/}
        {/*  </ListItemIcon>*/}
        {/*  Settings*/}
        {/*</MenuItem>*/}
        <MenuItem onClick={onLogoutHandler}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <UserModal
        openUserModal={openUserModal}
        setOpenUserModal={setOpenUserModal}
      >
        <CreateUser setOpenUserModal={setOpenUserModal} />
      </UserModal>
    </>
  )
}
