import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import FullScreenLoader from '../components/FullScreenLoader';
import Message from '../components/Message';
import { useGetAllDeviceDataQuery } from '../redux/api/deviceDataApi';
import Paper from '@mui/material/Paper';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import DeviceDataItem from '../components/device-data/device-data.component';
import DeviceFirmwareUpdateItem from '../components/firmware-update/device-firmware-update.component';
import {useGetAllFirmwareUpdatesByDeviceQuery, useGetAllFirmwareUpdatesQuery} from "../redux/api/firmwareUpdateApi";

const DeviceDataPage = () => {
  let { id }: any = useParams();
  const {
    isLoading: isLoadingDeviceData,
    isError: isErrorDeviceData,
    error: errorDeviceData,
    data: deviceData,
  } = useGetAllDeviceDataQuery(id);
  const {
    isLoading: isLoadingFirmwareUpdates,
    isError: isErrorFirmwareUpdates,
    error: errorFirmwareUpdates,
    data: firmwareUpdates,
  } = useGetAllFirmwareUpdatesByDeviceQuery(id);
  const [openDeviceModal, setOpenDeviceModal] = useState(false);

  if (isErrorDeviceData || isLoadingFirmwareUpdates) {
    return <FullScreenLoader />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {/*<h2>Данные с устройства</h2>*/}
        {/*{deviceData?.items?.length === 0 ? (*/}
        {/*  <Message type="warning" title="Внимание!">*/}
        {/*    Записи отсутствуют*/}
        {/*  </Message>*/}
        {/*) : (*/}
        {/*  <Paper*/}
        {/*    sx={{*/}
        {/*      p: 2,*/}
        {/*      display: 'flex',*/}
        {/*      flexDirection: 'column',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <Table size="small">*/}
        {/*      <TableHead>*/}
        {/*        <TableRow>*/}
        {/*          <TableCell>ID</TableCell>*/}
        {/*          <TableCell>Device</TableCell>*/}
        {/*          <TableCell>Coordinates</TableCell>*/}
        {/*          <TableCell>Data</TableCell>*/}
        {/*          <TableCell>Date</TableCell>*/}
        {/*        </TableRow>*/}
        {/*      </TableHead>*/}
        {/*      <TableBody>*/}
        {/*        {deviceData?.items?.map(deviceDataItem => (*/}
        {/*          <DeviceDataItem*/}
        {/*            key={deviceDataItem.id}*/}
        {/*            deviceDataItem={deviceDataItem}*/}
        {/*          />*/}
        {/*        ))}*/}
        {/*      </TableBody>*/}
        {/*    </Table>*/}
        {/*  </Paper>*/}
        {/*)}*/}

        <h2>Данные прошивок</h2>
        {firmwareUpdates?.items?.length === 0 ? (
          <Message type="warning" title="Внимание!">
            Записи отсутствуют
          </Message>
        ) : (
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>Rule</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>File</TableCell>
                  <TableCell>Percent</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {firmwareUpdates?.items?.map(firmwareUpdate => (
                  <DeviceFirmwareUpdateItem
                    key={firmwareUpdate.id}
                    firmwareUpdate={firmwareUpdate}
                  />
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export default DeviceDataPage;
