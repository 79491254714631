import { createApi } from '@reduxjs/toolkit/query/react';
import { LoginInput } from '../../pages/login.page';
import { RegisterInput } from '../../pages/register.page';
import customFetchBase from './customFetchBase';
import { GenericResponse, IResetPasswordRequest } from './types';
import { userApi } from './userApi';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    registerUser: builder.mutation<{ data: any; statusCode: string }, RegisterInput>({
      query(data) {
        return {
          url: 'v1/register',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data }: any = await queryFulfilled;
          if (data?.data?.accessToken) {
            localStorage.setItem('access_token', data?.data?.accessToken);
          }
          if (data?.data?.refreshToken) {
            localStorage.setItem('refresh_token', data?.data?.refreshToken);
          }
          await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {}
      },
    }),
    loginUser: builder.mutation<
      { data: any; statusCode: string },
      LoginInput
    >({
      query(data) {
        return {
          url: 'v1/login',
          method: 'POST',
          body: data,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data }: any = await queryFulfilled;
          if (data?.data?.accessToken) {
            localStorage.setItem('access_token', data?.data?.accessToken);
          }
          if (data?.data?.refreshToken) {
            localStorage.setItem('refresh_token', data?.data?.refreshToken);
          }
          await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {}
      },
    }),
    // logoutUser: builder.mutation<void, void>({
    //   query() {
    //     return {
    //       url: 'v1/logout',
    //     };
    //   },
    // }),
    logoutUser: builder.mutation<void, void>({
      query() {
        return {
          url: 'v1/logout',
        };
      },
    }),
    verifyEmail: builder.mutation<GenericResponse, string>({
      query(verificationCode) {
        return {
          url: `v1/verifyemail/${verificationCode}`,
        };
      },
    }),
    forgotPassword: builder.mutation<GenericResponse, { email: string }>({
      query(body) {
        return {
          url: `v1/forgot-password`,
          method: 'POST',
          body,
        };
      },
    }),
    resetPassword: builder.mutation<GenericResponse, IResetPasswordRequest>({
      query({ resetToken, password, passwordConfirm }) {
        return {
          url: `v1/reset-password/${resetToken}`,
          method: 'PATCH',
          body: { password, passwordConfirm },
        };
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useLogoutUserMutation,
  useVerifyEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
