import { Box, CircularProgress, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useUpdateFirmwareMutation } from '../../redux/api/firmwareApi';
import { IFirmware } from '../../redux/api/types';
import { FirmwareForm } from './form';
import { showErrorToast, showSuccessToast } from '../../Helpers/Toast';

interface IUpdateFirmwareProp {
  setOpenFirmwareModal: (openFirmwareModal: boolean) => void;
  firmware: IFirmware;
}

const UpdateFirmware: FC<IUpdateFirmwareProp> = ({
  setOpenFirmwareModal,
  firmware,
}) => {
  const [updateFirmware, { isLoading, isError, error, isSuccess }] =
    useUpdateFirmwareMutation();

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast('Успешно обновлено');
      setOpenFirmwareModal(false);
    }

    if (isError) {
      showErrorToast(error);
    }
  }, [isLoading]);

  const handleSubmit = (values: any) => {
    updateFirmware({
      id: firmware?.id!,
      firmware: values,
    });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
        <Typography variant="h5" component="h1">
          Редактирование прошивки
        </Typography>
        {isLoading && <CircularProgress size="1rem" color="primary" />}
      </Box>
      <FirmwareForm
        defaultValues={firmware}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};

export default UpdateFirmware;
