import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import { IDeviceResponse, IEnitityListResponse } from './types';

export const deviceApi = createApi({
  reducerPath: 'deviceApi',
  baseQuery: customFetchBase,
  tagTypes: ['Devices'],
  endpoints: builder => ({
    createDevice: builder.mutation<IDeviceResponse, any>({
      query(device) {
        return {
          url: '/v1/devices',
          method: 'POST',
          body: device,
        };
      },
      invalidatesTags: [{ type: 'Devices', id: 'LIST' }],
      transformResponse: (result: IDeviceResponse) => result,
    }),
    updateDevice: builder.mutation<
      IDeviceResponse,
      { id: string; device: any }
    >({
      query({ id, device }) {
        return {
          url: `/v1/devices/${id}`,
          method: 'PATCH',
          body: device,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: 'Devices', id },
              { type: 'Devices', id: 'LIST' },
            ]
          : [{ type: 'Devices', id: 'LIST' }],
      transformResponse: (response: { device: IDeviceResponse }) =>
        response.device,
    }),
    getDevice: builder.query<IDeviceResponse, string>({
      query(id) {
        return {
          url: `/v1/devices/${id}`,
        };
      },
      providesTags: (result, error, id) => [{ type: 'Devices', id }],
    }),
    getAllDevices: builder.query<IEnitityListResponse<IDeviceResponse>, any>({
      query({ page }: any) {
        return {
          url: `/v1/devices${page ? '?page=' + page : ''}`,
        };
      },
      providesTags: result =>
        result
          ? [
              ...result?.items?.map(({ id }) => ({
                type: 'Devices' as const,
                id,
              })),
              { type: 'Devices', id: 'LIST' },
            ]
          : [{ type: 'Devices', id: 'LIST' }],
      transformResponse: (results: {
        items: IDeviceResponse[];
        totalPages: number;
        page: number;
      }) => {
        return results;
      },
    }),
    deleteDevice: builder.mutation<IDeviceResponse, string>({
      query(id) {
        return {
          url: `/v1/devices/${id}`,
          method: 'Delete',
        };
      },
      invalidatesTags: [{ type: 'Devices', id: 'LIST' }],
    }),
  }),
});

export const {
  useCreateDeviceMutation,
  useDeleteDeviceMutation,
  useUpdateDeviceMutation,
  useGetAllDevicesQuery,
} = deviceApi;
