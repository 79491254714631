import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import { IEnitityListResponse, IFirmware } from './types';

export const firmwareApi = createApi({
  reducerPath: 'firmwareApi',
  baseQuery: customFetchBase,
  tagTypes: ['Firmwares'],
  endpoints: builder => ({
    createFirmware: builder.mutation<IFirmware, any>({
      query(firmware) {
        return {
          url: '/v1/firmwares',
          method: 'POST',
          body: firmware,
        };
      },
      invalidatesTags: [{ type: 'Firmwares', id: 'LIST' }],
      transformResponse: (result: { firmware: IFirmware }) => result.firmware,
    }),
    updateFirmware: builder.mutation<IFirmware, { id: number; firmware: any }>({
      query({ id, firmware }) {
        return {
          url: `/v1/firmwares/${id}`,
          method: 'PATCH',
          body: firmware,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: 'Firmwares', id },
              { type: 'Firmwares', id: 'LIST' },
            ]
          : [{ type: 'Firmwares', id: 'LIST' }],
      transformResponse: (response: { firmware: IFirmware }) =>
        response.firmware,
    }),
    getFirmware: builder.query<IFirmware, string>({
      query(id) {
        return {
          url: `/v1/firmwares/${id}`,
        };
      },
      providesTags: (result, error, id) => [{ type: 'Firmwares', id }],
    }),
    getAllFirmwares: builder.query<IEnitityListResponse<IFirmware>, void>({
      query() {
        return {
          url: `/v1/firmwares`,
        };
      },
      providesTags: result =>
        result
          ? [
              ...result?.items.map(({ id }) => ({
                type: 'Firmwares' as const,
                id,
              })),
              { type: 'Firmwares', id: 'LIST' },
            ]
          : [{ type: 'Firmwares', id: 'LIST' }],
      transformResponse: (results: {
        items: IFirmware[];
        totalPages: number;
        page: number;
      }) => results,
    }),
    deleteFirmware: builder.mutation<IFirmware, number>({
      query(id) {
        return {
          url: `/v1/firmwares/${id}`,
          method: 'Delete',
        };
      },
      invalidatesTags: [{ type: 'Firmwares', id: 'LIST' }],
    }),
  }),
});

export const {
  useCreateFirmwareMutation,
  useDeleteFirmwareMutation,
  useUpdateFirmwareMutation,
  useGetAllFirmwaresQuery,
} = firmwareApi;
