import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import FullScreenLoader from '../components/FullScreenLoader';
import Message from '../components/Message';
import DeviceItem from '../components/device/device.component';
import { useGetAllDevicesQuery } from '../redux/api/deviceApi';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import * as React from 'react';
import CreateDevice from '../components/device/create-device';
import DeviceModal from '../components/modals/device.modal';
import { useNavigate, useSearchParams } from 'react-router-dom';

const DevicesPage = () => {
  const [searchParams] = useSearchParams();
  const searchParamPage = searchParams.get('page');
  const [page, setPage] = useState<number>(
    searchParamPage ? parseInt(searchParamPage) : 1,
  );
  const navigate = useNavigate();
  const {
    isLoading,
    isError,
    error,
    data: devices,
  } = useGetAllDevicesQuery({ page });
  const [openDeviceModal, setOpenDeviceModal] = useState(false);

  useEffect(() => {
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
        });
      }
    }
  }, [isLoading]);

  function handleChangePage(e: any, value: any) {
    setPage(value);
    navigate(`/?page=${value}`);
  }

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h2>Устройства</h2>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 2,
          }}
        >
          <Button onClick={() => setOpenDeviceModal(true)}>+ Устройство</Button>
          {/*<Button>Фильтры</Button>*/}
        </Box>
        <DeviceModal
          openDeviceModal={openDeviceModal}
          setOpenDeviceModal={setOpenDeviceModal}
        >
          <CreateDevice setOpenDeviceModal={setOpenDeviceModal} />
        </DeviceModal>
        {devices?.items?.length === 0 ? (
          <Message type="warning" title="Внимание!">
            Записи отсутствуют
          </Message>
        ) : (
          <>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Number</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Version</TableCell>
                    <TableCell>Target</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {devices?.items?.map(device => (
                    <DeviceItem device={device} />
                  ))}
                </TableBody>
              </Table>
            </Paper>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              {devices?.totalPages && (
                <Pagination
                  defaultPage={1}
                  count={devices?.totalPages}
                  page={page}
                  onChange={handleChangePage}
                />
              )}
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default DevicesPage;
