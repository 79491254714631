import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { authApi } from './api/authApi';
import { deviceApi } from './api/deviceApi';
import { deviceDataApi } from './api/deviceDataApi';
import { userApi } from './api/userApi';
import { firmwareUpdateApi } from './api/firmwareUpdateApi';
import { firmwareApi } from './api/firmwareApi';
import userReducer from './features/userSlice';
import deviceReducer from './features/deviceSlice';
import deviceDataReducer from './features/deviceDataSlice';
import firmwareUpdateReducer from './features/firmwareUpdateSlice';
import firmwareReducer from './features/firmwareSlice';
import { commandApi } from './api/commandApi';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    // Connect the DeviceApi reducer to the store
    [deviceApi.reducerPath]: deviceApi.reducer,
    [deviceDataApi.reducerPath]: deviceDataApi.reducer,
    [firmwareUpdateApi.reducerPath]: firmwareUpdateApi.reducer,
    [firmwareApi.reducerPath]: firmwareApi.reducer,
    [commandApi.reducerPath]: commandApi.reducer,
    userState: userReducer,
    deviceState: deviceReducer,
    deviceDataState: deviceDataReducer,
    firmwareUpdateState: firmwareUpdateReducer,
    firmwareState: firmwareReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      userApi.middleware,
      // Add the DeviceApi middleware to the store
      deviceApi.middleware,
      deviceDataApi.middleware,
      firmwareUpdateApi.middleware,
      firmwareApi.middleware,
      commandApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
