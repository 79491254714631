import { TableCell, TableRow } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { FC, useEffect, useState } from 'react';
import DeviceModal from '../modals/device.modal';
import { useDeleteDeviceMutation } from '../../redux/api/deviceApi';
import { toast } from 'react-toastify';
import UpdateDevice from './update-device';
import { IDeviceResponse } from '../../redux/api/types';
import { format, parseISO } from 'date-fns';
import './device.styles.scss';
import * as React from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import { useNavigate } from 'react-router-dom';

interface IDeviceItemProps {
  device: IDeviceResponse;
}

const DeviceItem: FC<IDeviceItemProps> = ({ device }) => {
  const [openDeviceModal, setOpenDeviceModal] = useState(false);
  const navigate = useNavigate();
  const [deleteDevice, { isLoading, error, isSuccess, isError }] =
    useDeleteDeviceMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success('Device deleted successfully');
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onDeleteHandler = (id: string) => {
    if (window.confirm('Are you sure')) {
      deleteDevice(id);
    }
  };

  return (
    <>
      <TableRow key={device.id}>
        <TableCell>{device.id}</TableCell>
        <TableCell>{device.number}</TableCell>
        <TableCell>{device.type}</TableCell>
        <TableCell>{format(parseISO(device.createdAt), 'PPP')}</TableCell>
        <TableCell>{device.version}</TableCell>
        <TableCell>{device.target}</TableCell>
        <TableCell align="right">
          <ArticleIcon
            onClick={() => navigate(`/devices/${device.id}/data`)}
            fontSize="small"
            sx={{ mr: '0.6rem', cursor: 'pointer' }}
          />
          <ModeEditOutlineOutlinedIcon
            onClick={() => setOpenDeviceModal(true)}
            fontSize="small"
            sx={{ mr: '0.6rem', cursor: 'pointer' }}
          />
          <DeleteOutlinedIcon
            onClick={() => onDeleteHandler(device.id)}
            fontSize="small"
            sx={{ mr: '0.6rem', cursor: 'pointer' }}
          />
          <DeviceModal
            openDeviceModal={openDeviceModal}
            setOpenDeviceModal={setOpenDeviceModal}
          >
            <UpdateDevice
              setOpenDeviceModal={setOpenDeviceModal}
              device={device}
            />
          </DeviceModal>
        </TableCell>
      </TableRow>
    </>
  );
};

export default DeviceItem;
