import { FC, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid } from '@mui/material';
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { NEVER, number, object, string, ZodIssueCode } from 'zod';
import { useGetAllFirmwaresQuery } from '../../redux/api/firmwareApi';

const priorities: any = [
  ['Все', 0],
  ['Диапазон', 50],
  ['Конкретный', 100],
].map(i => ({
  id: i[1],
  label: i[0],
}));

const rules: any = [
  ['Обновлять всегда', 0],
  ['Только upgrade', 1],
  ['Только downgrade', 2],
  ['Никогда не обновлять', 3],
].map(i => ({
  id: i[1],
  label: i[0],
}));

const firmwareUpdateSchema = object({
  from: number().min(1).optional(),
  to: number().min(1).optional(),
  priority: number().min(0, 'Обязательное поле'),
  firmwareId: number().min(1, 'Обязательное поле'),
  rule: number().min(0, 'Обязательное поле'),
}).superRefine((data, ctx) => {
  if (
    data.priority === 50 &&
    (!data.from || !data.to || data.from > data.to)
  ) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      message: 'Конечный ID устройства должен быть больше или равен начальному',
      path: ['to'],
    });
  }

  if (
    data.priority === 100 &&
    (!data.from || !data.to || data.from !== data.to)
  ) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      message: 'Начальный ID и Конечный ID должны совпадать',
      path: ['to'],
    });
  }

  return NEVER;
});

const emptyValue = {
  from: undefined,
  to: undefined,
  priority: priorities[0].id,
  version: '',
  rule: rules[0].id,
};

export const FirmwareUpdateForm: FC<any> = ({
  handleSubmit,
  defaultValues,
}: any) => {
  const {
    isLoading,
    isError,
    error,
    data: firmwares,
  } = useGetAllFirmwaresQuery();

  const formContext = useForm({
    defaultValues: defaultValues || emptyValue,
    resolver: zodResolver(firmwareUpdateSchema),
  });
  const { setValue, watch } = formContext;
  const priority = watch('priority');

  useEffect(() => {
    if (priority === priorities[0].id) {
      setValue('from', undefined);
      setValue('to', undefined);
    }
  }, [priority]);

  if (!firmwares) {
    return <></>;
  }

  return (
    <FormContainer
      formContext={formContext}
      onSuccess={values => handleSubmit(values)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldElement
            name="from"
            type="number"
            size="small"
            label="Начальный ID устройства"
            disabled={priority === priorities[0].id}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldElement
            name="to"
            type="number"
            size="small"
            label="Конечный ID устройства"
            disabled={priority === priorities[0].id}
            // onChange={e => {
            //   setValue('from', e.target.value);
            // }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectElement
            name="priority"
            label="Приоритет"
            options={priorities}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <SelectElement
            name="firmwareId"
            label="Прошивка"
            options={firmwares.items.map((i: any) => ({
              id: i.id,
              label: i.name,
            }))}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <SelectElement
            name="rule"
            label="Правило"
            options={rules}
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" fullWidth>
            Сохранить
          </Button>
        </Grid>
      </Grid>
    </FormContainer>
  );
};
