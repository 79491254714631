import ReactDom from 'react-dom';
import React, {CSSProperties, FC} from 'react';
import { Container } from '@mui/material';

const OVERLAY_STYLES: CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,.3)",
  zIndex: 1000,
};

const MODAL_STYLES: CSSProperties = {
  position: "fixed",
  top: "10%",
  left: "50%",
  transform: "translateX(-50%)",
  transition: "all 300ms ease",
  backgroundColor: "white",
  overflowY: "scroll",
  zIndex: 1000,
};

type ICommandModal = {
  openCommandModal: boolean;
  setOpenCommandModal: (openCommandModal: boolean) => void;
  children: React.ReactNode;
};

const CommandModal: FC<ICommandModal> = ({
  openCommandModal,
  setOpenCommandModal,
  children,
}) => {
  if (!openCommandModal) return null;
  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} onClick={() => setOpenCommandModal(false)} />
      <Container style={MODAL_STYLES} maxWidth="sm" sx={{ p: '2rem 1rem', borderRadius: 1 }}>
        {children}
      </Container>
    </>,
    document.getElementById('command-modal') as HTMLElement,
  );
};

export default CommandModal;
