import { Box, CircularProgress, Typography } from '@mui/material';

import { FC, useEffect } from 'react';
import { useCreateFirmwareUpdateMutation } from '../../redux/api/firmwareUpdateApi';
import { FirmwareUpdateForm } from './form';
import { showErrorToast, showSuccessToast } from '../../Helpers/Toast';

interface ICreateFirmwareUpdateProp {
  setOpenFirmwareUpdateModal: (openFirmwareUpdateModal: boolean) => void;
}

const CreateFirmwareUpdate: FC<ICreateFirmwareUpdateProp> = ({
  setOpenFirmwareUpdateModal,
}) => {
  const [createFirmwareUpdate, { isLoading, isError, error, isSuccess }] =
    useCreateFirmwareUpdateMutation();

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast('Успешно создано');
      setOpenFirmwareUpdateModal(false);
    }

    if (isError) {
      showErrorToast(error);
    }
  }, [isLoading]);

  const handleSubmit = (values: any) => {
    createFirmwareUpdate(values);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
        <Typography variant="h5" component="h1">
          Создание задания на прошивку
        </Typography>
        {isLoading && <CircularProgress size="1rem" color="primary" />}
      </Box>
      <FirmwareUpdateForm handleSubmit={handleSubmit} />
    </Box>
  );
};

export default CreateFirmwareUpdate;
