import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFirmwareUpdate } from '../api/types';

interface IFirmwareUpdateState {
  firmwareUpdate: IFirmwareUpdate | null;
}

const initialState: IFirmwareUpdateState = {
  firmwareUpdate: null,
};

export const firmwareUpdateSlice = createSlice({
  initialState,
  name: 'firmwareUpdateSlice',
  reducers: {
    firmwareUpdateState: (state, action: PayloadAction<IFirmwareUpdate>) => {
      state.firmwareUpdate = action.payload;
    },
  },
});

export default firmwareUpdateSlice.reducer;

export const { firmwareUpdateState } = firmwareUpdateSlice.actions;
