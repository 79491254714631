import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import FullScreenLoader from '../components/FullScreenLoader';
import Message from '../components/Message';
import FirmwareUpdateItem from '../components/firmware-update/firmware-update.component';
import Paper from '@mui/material/Paper';
import * as React from 'react';
import CreateFirmwareUpdate from '../components/firmware-update/create-firmware-update';
import FirmwareUpdateModal from '../components/modals/firmwareUpdate.modal';
import { useGetAllFirmwareUpdatesQuery } from '../redux/api/firmwareUpdateApi';
import { showErrorToast } from '../Helpers/Toast';
import { useGetAllFirmwaresQuery } from '../redux/api/firmwareApi';

const FirmwareUpdatesPage = () => {
  const {
    isLoading,
    isError,
    error,
    data: firmwareUpdates,
  } = useGetAllFirmwareUpdatesQuery();
  const [openFirmwareUpdateModal, setOpenFirmwareUpdateModal] = useState(false);

  useEffect(() => {
    if (isError) {
      showErrorToast(error);
    }
  }, [isLoading]);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h2>Обновления прошивок</h2>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 2,
          }}
        >
          <Button onClick={() => setOpenFirmwareUpdateModal(true)}>
            + Обновление
          </Button>
        </Box>
        <FirmwareUpdateModal
          openFirmwareUpdateModal={openFirmwareUpdateModal}
          setOpenFirmwareUpdateModal={setOpenFirmwareUpdateModal}
        >
          <CreateFirmwareUpdate
            setOpenFirmwareUpdateModal={setOpenFirmwareUpdateModal}
          />
        </FirmwareUpdateModal>
        {firmwareUpdates?.items?.length === 0 ? (
          <Message type="warning" title="Внимание!">
            Записи отсутствуют
          </Message>
        ) : (
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>Rule</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {firmwareUpdates?.items?.map(firmwareUpdate => (
                  <FirmwareUpdateItem
                    key={firmwareUpdate.id}
                    firmwareUpdate={firmwareUpdate}
                  />
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export default FirmwareUpdatesPage;
