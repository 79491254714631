import { Box, CircularProgress, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useUpdateFirmwareUpdateMutation } from '../../redux/api/firmwareUpdateApi';
import { IFirmware, IFirmwareUpdate } from '../../redux/api/types';
import { FirmwareUpdateForm } from './form';
import { showErrorToast, showSuccessToast } from '../../Helpers/Toast';

interface IUpdateFirmwareUpdateProp {
  setOpenFirmwareUpdateModal: (openFirmwareUpdateModal: boolean) => void;
  firmwareUpdate: IFirmwareUpdate;
}

const UpdateFirmwareUpdate: FC<IUpdateFirmwareUpdateProp> = ({
  setOpenFirmwareUpdateModal,
  firmwareUpdate,
}) => {
  const [updateFirmwareUpdate, { isLoading, isError, error, isSuccess }] =
    useUpdateFirmwareUpdateMutation();

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast('Успешно обновлено');
      setOpenFirmwareUpdateModal(false);
    }

    if (isError) {
      showErrorToast(error);
    }
  }, [isLoading]);

  const handleSubmit = (values: any) => {
    updateFirmwareUpdate({
      id: firmwareUpdate?.id!,
      firmwareUpdate: values,
    });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
        <Typography variant="h5" component="h1">
          Создание задания на прошивку
        </Typography>
        {isLoading && <CircularProgress size="1rem" color="primary" />}
      </Box>
      <FirmwareUpdateForm
        defaultValues={firmwareUpdate}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};

export default UpdateFirmwareUpdate;
