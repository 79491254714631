import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import { IEnitityListResponse, ICommand } from './types';

export const commandApi = createApi({
  reducerPath: 'commandApi',
  baseQuery: customFetchBase,
  tagTypes: ['Commands'],
  endpoints: builder => ({
    createCommand: builder.mutation<ICommand, any>({
      query(command) {
        return {
          url: '/v1/commands',
          method: 'POST',
          body: command,
        };
      },
      invalidatesTags: [{ type: 'Commands', id: 'LIST' }],
      transformResponse: (result: { command: ICommand }) => result.command,
    }),
    updateCommand: builder.mutation<ICommand, { id: number; command: any }>({
      query({ id, command }) {
        return {
          url: `/v1/commands/${id}`,
          method: 'PATCH',
          body: command,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: 'Commands', id },
              { type: 'Commands', id: 'LIST' },
            ]
          : [{ type: 'Commands', id: 'LIST' }],
      transformResponse: (response: { command: ICommand }) => response.command,
    }),
    getCommand: builder.query<ICommand, string>({
      query(id) {
        return {
          url: `/v1/commands/${id}`,
        };
      },
    }),
    getAllCommands: builder.query<IEnitityListResponse<ICommand>, void>({
      query() {
        return {
          url: `/v1/commands`,
        };
      },
      providesTags: result =>
        result
          ? [
              ...result?.items?.map(({ id }) => ({
                type: 'Commands' as const,
                id,
              })),
              { type: 'Commands', id: 'LIST' },
            ]
          : [{ type: 'Commands', id: 'LIST' }],
      transformResponse: (results: {
        items: ICommand[];
        totalPages: number;
        page: number;
      }) => results,
    }),
    deleteCommand: builder.mutation<ICommand, number>({
      query(id) {
        return {
          url: `/v1/commands/${id}`,
          method: 'Delete',
        };
      },
      invalidatesTags: [{ type: 'Commands', id: 'LIST' }],
    }),
  }),
});

export const {
  useCreateCommandMutation,
  useDeleteCommandMutation,
  useUpdateCommandMutation,
  useGetAllCommandsQuery,
} = commandApi;
