import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDeviceResponse } from '../api/types';

interface IDeviceState {
  device: IDeviceResponse | null;
}

const initialState: IDeviceState = {
  device: null,
};

export const deviceSlice = createSlice({
  initialState,
  name: 'deviceSlice',
  reducers: {
    deviceState: (state, action: PayloadAction<IDeviceResponse>) => {
      state.device = action.payload;
    },
  },
});

export default deviceSlice.reducer;

export const { deviceState } = deviceSlice.actions;
