import { FC, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid } from '@mui/material';
import {
  FormContainer,
  TextFieldElement,
  TextareaAutosizeElement,
} from 'react-hook-form-mui';
import {number, object, string} from 'zod';

const commandSchema = object({
  title: string().min(1, 'Обязательное поле'),
  text: string().min(1, 'Обязательное поле'),
  deviceId: number().min(1, 'Обязательное поле'),
});

const emptyValue = {
  title: '',
  text: '',
  deviceId: '',
};

export const CommandForm: FC<any> = ({ handleSubmit, defaultValues }: any) => {
  return (
    <FormContainer
      defaultValues={defaultValues || emptyValue}
      resolver={zodResolver(commandSchema)}
      onSuccess={values => handleSubmit(values)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldElement
            name="title"
            type="string"
            size="small"
            label="Название"
          />
        </Grid>
        <Grid item xs={12}>
          <TextareaAutosizeElement
            fullWidth
            name="text"
            size="small"
            rows={15}
            label="Текст команды"
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldElement
            name="deviceId"
            type="number"
            size="small"
            label="ID устройства"
          />
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained" fullWidth>
            Сохранить
          </Button>
        </Grid>
      </Grid>
    </FormContainer>
  );
};
