import { Box, CircularProgress, Typography } from '@mui/material';

import { FC, useEffect } from 'react';
import { useCreateFirmwareMutation } from '../../redux/api/firmwareApi';
import { FirmwareForm } from './form';
import { showErrorToast, showSuccessToast } from '../../Helpers/Toast';

interface ICreateFirmwareProp {
  setOpenFirmwareModal: (openFirmwareModal: boolean) => void;
}

const CreateFirmware: FC<ICreateFirmwareProp> = ({ setOpenFirmwareModal }) => {
  const [createFirmware, { isLoading, isError, error, isSuccess }] =
    useCreateFirmwareMutation();

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast('Успешно создано');
      setOpenFirmwareModal(false);
    }

    if (isError) {
      showErrorToast(error);
    }
  }, [isLoading]);

  const handleSubmit = (values: any) => {
    createFirmware(values);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
        <Typography variant="h5" component="h1">
          Создание прошивки
        </Typography>
        {isLoading && <CircularProgress size="1rem" color="primary" />}
      </Box>
      <FirmwareForm handleSubmit={handleSubmit} />
    </Box>
  );
};

export default CreateFirmware;
