import { TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import { IFirmwareUpdate } from '../../redux/api/types';
import { format, parseISO } from 'date-fns';
// import './firmwareUpdate.styles.scss';
import * as React from 'react';

interface IDeviceFirmwareUpdateItemProps {
  firmwareUpdate: IFirmwareUpdate;
}

const firmwareStatuses: any = {
  0: 'Не загружено',
  1: 'Ошибка обновления',
  2: 'Обновление прервано',
  3: 'Обновление загружено',
  4: 'Обновление применено',
};

const DeviceFirmwareUpdateItem: FC<IDeviceFirmwareUpdateItemProps> = ({
  firmwareUpdate,
}) => {
  return (
    <>
      <TableRow key={firmwareUpdate.id}>
        <TableCell>{firmwareUpdate.id}</TableCell>
        <TableCell>{firmwareUpdate.from || '-'}</TableCell>
        <TableCell>{firmwareUpdate.to || '-'}</TableCell>
        <TableCell>{firmwareUpdate.type}</TableCell>
        <TableCell>{firmwareUpdate.priority}</TableCell>
        <TableCell>
          {firmwareUpdate.statuses.length && (
            <>
              <span>From: {firmwareUpdate.statuses[0]?.fromTarget}</span>
              <br />
            </>
          )}
          {firmwareUpdate.firmware.target}
        </TableCell>
        <TableCell>
          {firmwareUpdate.statuses.length && (
            <>
              <span>From: {firmwareUpdate.statuses[0]?.fromVersion}</span>
              <br />
            </>
          )}
          {firmwareUpdate.firmware.version}
        </TableCell>
        <TableCell>{firmwareUpdate.rule}</TableCell>
        <TableCell>
          {format(parseISO(firmwareUpdate.createdAt), 'PPP')}
        </TableCell>
        <TableCell>{firmwareUpdate.firmware.name}</TableCell>
        <TableCell>
          {firmwareUpdate.statuses[0]?.percent
            ? `${firmwareUpdate.statuses[0]?.percent}%`
            : null}
        </TableCell>
        <TableCell>
          {firmwareUpdate.statuses[0]?.status
            ? firmwareStatuses[firmwareUpdate.statuses[0]?.status]
            : null}
        </TableCell>
      </TableRow>
    </>
  );
};

export default DeviceFirmwareUpdateItem;
