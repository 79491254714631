import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { logout } from '../features/userSlice';

const baseUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/api/`;

// Create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const accessToken = localStorage.getItem('access_token');

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return headers
  }
});

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if ((result.error as any)?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');

      if (!accessToken || !refreshToken) {
        api.dispatch(logout());
        window.location.href = '/login';
      }

      try {
        const refreshResult: any = await baseQuery(
          {
            url: 'v1/refresh',
            method: 'POST',
            body: {
              accessToken: localStorage.getItem('access_token'),
              refreshToken: localStorage.getItem('refresh_token'),
            }
          },
          api,
          extraOptions
        );

        if (refreshResult.data?.data) {
          // Retry the initial query
          localStorage.setItem('access_token', refreshResult.data?.data?.accessToken);
          localStorage.setItem('refresh_token', refreshResult.data?.data?.refreshToken);
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
          window.location.href = '/login';
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export default customFetchBase;
