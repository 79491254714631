import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import { IEnitityListResponse, IFirmwareUpdate } from './types';

export const firmwareUpdateApi = createApi({
  reducerPath: 'firmwareUpdateApi',
  baseQuery: customFetchBase,
  tagTypes: ['FirmwareUpdates'],
  endpoints: builder => ({
    createFirmwareUpdate: builder.mutation<IFirmwareUpdate, any>({
      query(firmwareUpdate) {
        return {
          url: '/v1/firmware_updates',
          method: 'POST',
          body: firmwareUpdate,
        };
      },
      invalidatesTags: [{ type: 'FirmwareUpdates', id: 'LIST' }],
      transformResponse: (result: { firmwareUpdate: IFirmwareUpdate }) =>
        result.firmwareUpdate,
    }),
    updateFirmwareUpdate: builder.mutation<
      IFirmwareUpdate,
      { id: number; firmwareUpdate: any }
    >({
      query({ id, firmwareUpdate }) {
        return {
          url: `/v1/firmware_updates/${id}`,
          method: 'PATCH',
          body: firmwareUpdate,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: 'FirmwareUpdates', id },
              { type: 'FirmwareUpdates', id: 'LIST' },
            ]
          : [{ type: 'FirmwareUpdates', id: 'LIST' }],
      transformResponse: (response: { firmwareUpdate: IFirmwareUpdate }) =>
        response.firmwareUpdate,
    }),
    getFirmwareUpdate: builder.query<IFirmwareUpdate, string>({
      query(id) {
        return {
          url: `/v1/firmware_updates/${id}`,
        };
      },
      providesTags: (result, error, id) => [{ type: 'FirmwareUpdates', id }],
    }),
    getAllFirmwareUpdates: builder.query<
      IEnitityListResponse<IFirmwareUpdate>,
      void
    >({
      query() {
        return {
          url: `/v1/firmware_updates`,
        };
      },
      providesTags: result =>
        result
          ? [
              ...result?.items.map(({ id }) => ({
                type: 'FirmwareUpdates' as const,
                id,
              })),
              { type: 'FirmwareUpdates', id: 'LIST' },
            ]
          : [{ type: 'FirmwareUpdates', id: 'LIST' }],
      transformResponse: (results: {
        items: IFirmwareUpdate[];
        totalPages: number;
        page: number;
      }) => results,
    }),
    getAllFirmwareUpdatesByDevice: builder.query<
      IEnitityListResponse<IFirmwareUpdate>,
      number
    >({
      query(deviceId: number) {
        return {
          url: `/v1/devices/${deviceId}/firmware_updates`,
        };
      },
      transformResponse: (results: {
        items: IFirmwareUpdate[];
        totalPages: number;
        page: number;
      }) => results,
    }),
    deleteFirmwareUpdate: builder.mutation<IFirmwareUpdate, number>({
      query(id) {
        return {
          url: `/v1/firmware_updates/${id}`,
          method: 'Delete',
        };
      },
      invalidatesTags: [{ type: 'FirmwareUpdates', id: 'LIST' }],
    }),
  }),
});

export const {
  useCreateFirmwareUpdateMutation,
  useDeleteFirmwareUpdateMutation,
  useUpdateFirmwareUpdateMutation,
  useGetAllFirmwareUpdatesQuery,
  useGetAllFirmwareUpdatesByDeviceQuery,
} = firmwareUpdateApi;
