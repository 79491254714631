import {
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import {
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { object, string, TypeOf, z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useCreateUserMutation } from '../../redux/api/userApi';

interface ICreateUserProp {
  setOpenUserModal: (openUserModal: boolean) => void;
}

const createUserSchema = object({
  name: string().min(1, 'Full name is required').max(120),
  email: string()
    .min(1, 'Email address is required')
    .email('Email Address is invalid'),
  password: string()
    .min(1, 'Password is required')
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  passwordConfirm: string().min(1, 'Please confirm your password'),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ['passwordConfirm'],
  message: 'Passwords do not match',
});

export type ICreateUser = TypeOf<typeof createUserSchema>;

const CreateUser: FC<ICreateUserProp> = ({ setOpenUserModal }) => {
  const [createUser, { isLoading, isError, error, isSuccess }] =
    useCreateUserMutation();

  const methods = useForm<ICreateUser>({
    resolver: zodResolver(createUserSchema),
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success('User created successfully');
      setOpenUserModal(false);
    }

    if (isError) {
      if (Array.isArray((error as any).data.message)) {
        (error as any).data.message.forEach((el: any) =>
          toast.error(el, {
            position: 'top-right',
          })
        );
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (methods.formState.isSubmitting) {
      methods.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState.isSubmitting]);

  const onSubmitHandler: SubmitHandler<ICreateUser> = (values: ICreateUser) => {
    createUser(values);
  };

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
        <Typography variant='h5' component='h1'>
          Create User
        </Typography>
        {isLoading && <CircularProgress size='1rem' color='primary' />}
      </Box>
      <FormProvider {...methods}>
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          <TextField
            label='Email'
            fullWidth
            sx={{ mb: '1rem' }}
            {...methods.register('email')}
          />
          <TextField
            label='Name'
            fullWidth
            sx={{ mb: '1rem' }}
            {...methods.register('name')}
          />
          <TextField
            type='password'
            label='Password'
            fullWidth
            sx={{ mb: '1rem' }}
            {...methods.register('password')}
          />
          <TextField
            type='password'
            label='Confirm Password'
            fullWidth
            sx={{ mb: '1rem' }}
            {...methods.register('passwordConfirm')}
          />
          <LoadingButton
            variant='contained'
            fullWidth
            sx={{ py: '0.8rem', mt: 4, backgroundColor: '#2363eb' }}
            type='submit'
            loading={isLoading}
          >
            Create User
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default CreateUser;
