import { createApi } from "@reduxjs/toolkit/query/react";
import { setUser } from "../features/userSlice";
import customFetchBase from "./customFetchBase";
import { IUserResponse, IUser } from "./types";
import { ICreateUser } from "../../components/user/create-user";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: customFetchBase,
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getMe: builder.query<IUser, null>({
      query() {
        return {
          url: 'v1/users/me',
        };
      },
      transformResponse: (result: { data: IUser }) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {}
      },
    }),
    createUser: builder.mutation<IUserResponse, ICreateUser>({
      query(user) {
        return {
          url: '/v1/users',
          method: 'POST',
          body: user,
        };
      },
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
      transformResponse: (result: { data: IUserResponse }) => result.data,
    }),
    updateUser: builder.mutation<IUserResponse, { id: string; user: any }>(
      {
        query({ id, user }) {
          return {
            url: `/v1/users/${id}`,
            method: 'PATCH',
            body: user,
          };
        },
        invalidatesTags: (result, error, { id }) =>
          result
            ? [
              { type: 'Users', id },
              { type: 'Users', id: 'LIST' },
            ]
            : [{ type: 'Users', id: 'LIST' }],
        transformResponse: (response: { data: IUserResponse }) => response.data,
      }
    ),
    deleteUser: builder.mutation<IUserResponse, string>({
      query(id) {
        return {
          url: `/v1/user/${id}`,
          method: 'Delete',
        };
      },
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),
  }),
});

export const {
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApi;
