import { Box, CircularProgress, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { showErrorToast, showSuccessToast } from '../../Helpers/Toast';
import { useCreateCommandMutation } from '../../redux/api/commandApi';
import { CommandForm } from './form';

interface ICreateCommandProp {
  setOpenCommandModal: (openCommandModal: boolean) => void;
}

const CreateCommand: FC<ICreateCommandProp> = ({ setOpenCommandModal }) => {
  const [createCommand, { isLoading, isError, error, isSuccess }] =
    useCreateCommandMutation();

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast('Успешно создано');
      setOpenCommandModal(false);
    }

    if (isError) {
      showErrorToast(error);
    }
  }, [isLoading]);

  const handleSubmit = (values: any) => {
    createCommand(values);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
        <Typography variant="h5" component="h1">
          Создание команды
        </Typography>
        {isLoading && <CircularProgress size="1rem" color="primary" />}
      </Box>
      <CommandForm handleSubmit={handleSubmit} />
    </Box>
  );
};

export default CreateCommand;
