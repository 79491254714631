import { Box, CircularProgress, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useCreateDeviceMutation } from '../../redux/api/deviceApi';
import { DeviceForm } from './form';
import { showErrorToast, showSuccessToast } from '../../Helpers/Toast';

interface ICreateDeviceProp {
  setOpenDeviceModal: (openDeviceModal: boolean) => void;
}

const CreateDevice: FC<ICreateDeviceProp> = ({ setOpenDeviceModal }) => {
  const [createDevice, { isLoading, isError, error, isSuccess }] =
    useCreateDeviceMutation();

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast('Успешно создано');
      setOpenDeviceModal(false);
    }

    if (isError) {
      showErrorToast(error);
    }
  }, [isLoading]);

  const handleSubmit = (values: any) => {
    createDevice(values);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
        <Typography variant="h5" component="h1">
          Создание устройства
        </Typography>
        {isLoading && <CircularProgress size="1rem" color="primary" />}
      </Box>
      <DeviceForm handleSubmit={handleSubmit} />
    </Box>
  );
};

export default CreateDevice;
