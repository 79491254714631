import { CssBaseline } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/layout';
import ProfilePage from './pages/profile.page';
import DevicesPage from './pages/devices.page';
import DeviceDataPage from './pages/device-data.page';
import LoginPage from './pages/login.page';
import RegisterPage from './pages/register.page';
import UnauthorizePage from './pages/unauthorize.page';
import RequireUser from './components/requireUser';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminPage from './pages/admin.page';
import EmailVerificationPage from './pages/verifyemail.page';
import ResetPasswordPage from './pages/reset-password.page';
import ForgotPasswordPage from './pages/forgot-password.page';
import AccountPage from './pages/account.page';
import FirmwareUpdatesPage from './pages/firmware-updates.page';
import FirmwaresPage from './pages/firmwares.page';
import CommandsPage from './pages/commands.page';

function App() {
  return (
    <>
      <CssBaseline />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route element={<RequireUser allowedRoles={['USER']} />}>
            <Route path="/" index element={<DevicesPage />} />
          </Route>
          <Route element={<RequireUser allowedRoles={['USER']} />}>
            <Route path="devices/:id/data" element={<DeviceDataPage />} />
          </Route>
          <Route element={<RequireUser allowedRoles={['USER']} />}>
            <Route
              path="/firmware_updates"
              index
              element={<FirmwareUpdatesPage />}
            />
          </Route>
          <Route element={<RequireUser allowedRoles={['USER']} />}>
            <Route
              path="/firmwares"
              index
              element={<FirmwaresPage />}
            />
          </Route>
          <Route element={<RequireUser allowedRoles={['USER']} />}>
            <Route
              path="/commands"
              index
              element={<CommandsPage />}
            />
          </Route>
          <Route element={<RequireUser allowedRoles={['USER', 'ADMIN']} />}>
            <Route path="profile" element={<ProfilePage />} />
          </Route>
          <Route element={<RequireUser allowedRoles={['USER', 'ADMIN']} />}>
            <Route path="account" element={<AccountPage />} />
          </Route>
          <Route element={<RequireUser allowedRoles={['ADMIN']} />}>
            <Route path="admin" element={<AdminPage />} />
          </Route>
          <Route path="unauthorized" element={<UnauthorizePage />} />
        </Route>
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="verifyemail" element={<EmailVerificationPage />}>
          <Route path=":verificationCode" element={<EmailVerificationPage />} />
        </Route>
        <Route path="forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="resetpassword" element={<ResetPasswordPage />}>
          <Route path=":resetToken" element={<ResetPasswordPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
