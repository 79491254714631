import { Box, CircularProgress, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { showErrorToast, showSuccessToast } from '../../Helpers/Toast';
import { useUpdateCommandMutation } from '../../redux/api/commandApi';
import { ICommand } from '../../redux/api/types';
import { CommandForm } from './form';

interface IUpdateCommandProp {
  setOpenCommandModal: (openCommandModal: boolean) => void;
  command: ICommand;
}

const UpdateCommand: FC<IUpdateCommandProp> = ({
  setOpenCommandModal,
  command,
}) => {
  const [updateCommand, { isLoading, isError, error, isSuccess }] =
    useUpdateCommandMutation();

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast('Успешно обновлено');
      setOpenCommandModal(false);
    }

    if (isError) {
      showErrorToast(error);
    }
  }, [isLoading]);

  const handleSubmit = (values: any) => {
    updateCommand({
      id: command?.id!,
      command: values,
    });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
        <Typography variant="h5" component="h1">
          Редактирование команды
        </Typography>
        {isLoading && <CircularProgress size="1rem" color="primary" />}
      </Box>
      <CommandForm defaultValues={command} handleSubmit={handleSubmit} />
    </Box>
  );
};

export default UpdateCommand;
