import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import List from '@mui/material/List';
import * as React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useLocation, useNavigate } from 'react-router-dom';

export default function LeftMenu() {
  const navigator = useNavigate();
  const l = useLocation();
  return (
    <List component="nav">
      <ListItemButton
        onClick={() => navigator('/')}
        selected={l.pathname === '/'}
      >
        <ListItemText primary="Устройства" />
      </ListItemButton>

      <ListItemButton
        onClick={() => navigator('/firmware_updates')}
        selected={l.pathname === '/firmware_updates'}
      >
        <ListItemText primary="Задания на прошивки" />
      </ListItemButton>

      <ListItemButton
        onClick={() => navigator('/firmwares')}
        selected={l.pathname === '/firmwares'}
      >
        <ListItemText primary="Прошивки" />
      </ListItemButton>

      <ListItemButton
        onClick={() => navigator('/commands')}
        selected={l.pathname === '/commands'}
      >
        <ListItemText primary="Команды" />
      </ListItemButton>
    </List>
  );
}
