import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import FullScreenLoader from '../components/FullScreenLoader';
import Message from '../components/Message';
import FirmwareItem from '../components/firmware/firmware.component';
import Paper from '@mui/material/Paper';
import * as React from 'react';
import CreateFirmware from '../components/firmware/create-firmware';
import FirmwareModal from '../components/modals/firmware.modal';
import { useGetAllFirmwaresQuery } from '../redux/api/firmwareApi';
import { showErrorToast } from '../Helpers/Toast';

const FirmwaresPage = () => {
  const {
    isLoading,
    isError,
    error,
    data: firmwares,
  } = useGetAllFirmwaresQuery();
  const [openFirmwareModal, setOpenFirmwareModal] = useState(false);

  useEffect(() => {
    if (isError) {
      showErrorToast(error);
    }
  }, [isLoading]);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h2>Прошивки</h2>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 2,
          }}
        >
          <Button onClick={() => setOpenFirmwareModal(true)}>+ Прошивка</Button>
        </Box>
        <FirmwareModal
          openFirmwareModal={openFirmwareModal}
          setOpenFirmwareModal={setOpenFirmwareModal}
        >
          <CreateFirmware setOpenFirmwareModal={setOpenFirmwareModal} />
        </FirmwareModal>
        {firmwares?.items?.length === 0 ? (
          <Message type="warning" title="Внимание!">
            Записи отсутствуют
          </Message>
        ) : (
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {firmwares?.items?.map(firmware => (
                  <FirmwareItem firmware={firmware} />
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export default FirmwaresPage;
