import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDeviceData } from '../api/types';

interface IDeviceDataState {
  deviceData: IDeviceData | null;
}

const initialState: IDeviceDataState = {
  deviceData: null,
};

export const deviceDataSlice = createSlice({
  initialState,
  name: 'deviceDataSlice',
  reducers: {
    deviceDataState: (state, action: PayloadAction<IDeviceData>) => {
      state.deviceData = action.payload;
    },
  },
});

export default deviceDataSlice.reducer;

export const { deviceDataState } = deviceDataSlice.actions;
