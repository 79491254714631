import { TableCell, TableRow } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ICommand } from '../../redux/api/types';
import { format, parseISO } from 'date-fns';
import * as React from 'react';
import { useDeleteCommandMutation } from '../../redux/api/commandApi';
import UpdateCommand from './update-command';
import CommandModal from '../modals/command.modal';

interface ICommandItemProps {
  command: ICommand;
}

const CommandItem: FC<ICommandItemProps> = ({ command }: any) => {
  const [openCommandModal, setOpenCommandModal] = useState(false);
  const [deleteCommand, { isLoading, error, isSuccess, isError }] =
    useDeleteCommandMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success('command deleted successfully');
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onDeleteHandler = (id: number) => {
    if (window.confirm('Are you sure')) {
      deleteCommand(id);
    }
  };

  return (
    <>
      <TableRow key={command.id}>
        <TableCell>{command.id}</TableCell>
        <TableCell>{command.title}</TableCell>
        <TableCell>{command.status}</TableCell>
        <TableCell>{command.deviceId}</TableCell>
        <TableCell>{format(parseISO(command.createdAt), 'PPP')}</TableCell>
        <TableCell align="right">
          <ModeEditOutlineOutlinedIcon
            onClick={() => setOpenCommandModal(true)}
            fontSize="small"
            sx={{ mr: '0.6rem', cursor: 'pointer' }}
          />
          <DeleteOutlinedIcon
            onClick={() => onDeleteHandler(command.id)}
            fontSize="small"
            sx={{ mr: '0.6rem', cursor: 'pointer' }}
          />
          <CommandModal
            openCommandModal={openCommandModal}
            setOpenCommandModal={setOpenCommandModal}
          >
            <UpdateCommand
              setOpenCommandModal={setOpenCommandModal}
              command={command}
            />
          </CommandModal>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CommandItem;
