import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import {IDeviceData, IDeviceDataList, IEnitityListResponse} from './types';

export const deviceDataApi = createApi({
  reducerPath: 'deviceDataApi',
  baseQuery: customFetchBase,
  tagTypes: ['DeviceData'],
  endpoints: builder => ({
    getAllDeviceData: builder.query<IEnitityListResponse<IDeviceData>, void>({
      query(id) {
        return {
          url: `/v1/devices/${id}/device_tel_data`,
        };
      },
      providesTags: result =>
        result
          ? [
              ...result?.items?.map(({ id }) => ({
                type: 'DeviceData' as const,
                id,
              })),
              { type: 'DeviceData', id: 'LIST' },
            ]
          : [{ type: 'DeviceData', id: 'LIST' }],
      transformResponse: (results: {
        items: IDeviceData[];
        totalPages: number;
        page: number;
      }) => results,
    }),
  }),
});

export const { useGetAllDeviceDataQuery } = deviceDataApi;
