import { FC } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid } from '@mui/material';
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { object, string } from 'zod';

const deviceSchema = object({
  number: string().min(1, 'Обязательное поле'),
  type: string().min(1, 'Обязательное поле'),
});

const emptyValue = {
  number: '',
  type: '',
};

export const DeviceForm: FC<any> = ({
  handleSubmit,
  defaultValues,
}: any) => {
  return (
    <FormContainer
      defaultValues={defaultValues || emptyValue}
      resolver={zodResolver(deviceSchema)}
      onSuccess={values => handleSubmit(values)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldElement
            name="number"
            type="text"
            size="small"
            label="Номер устройства"
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldElement
            name="type"
            type="text"
            size="small"
            label="Тип"
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" fullWidth>
            Сохранить
          </Button>
        </Grid>
      </Grid>
    </FormContainer>
  );
};
