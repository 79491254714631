import { CardContent, TableCell, TableRow } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { FC, useEffect, useState } from 'react';
import FirmwareUpdateModal from '../modals/firmwareUpdate.modal';
import { useDeleteFirmwareUpdateMutation } from '../../redux/api/firmwareUpdateApi';
import { toast } from 'react-toastify';
import UpdatefirmwareUpdate from './update-firmware-update';
import { IFirmwareUpdate } from '../../redux/api/types';
import { format, parseISO } from 'date-fns';
// import './firmwareUpdate.styles.scss';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

interface IFirmwareUpdateItemProps {
  firmwareUpdate: IFirmwareUpdate;
}

const FirmwareUpdateItem: FC<IFirmwareUpdateItemProps> = ({
  firmwareUpdate,
}) => {
  const [openFirmwareUpdateModal, setOpenFirmwareUpdateModal] = useState(false);
  const navigate = useNavigate();
  const [deleteFirmwareUpdate, { isLoading, error, isSuccess, isError }] =
    useDeleteFirmwareUpdateMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success('firmwareUpdate deleted successfully');
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onDeleteHandler = (id: number) => {
    if (window.confirm('Are you sure')) {
      deleteFirmwareUpdate(id);
    }
  };

  return (
    <>
      <TableRow key={firmwareUpdate.id}>
        <TableCell>{firmwareUpdate.id}</TableCell>
        <TableCell>{firmwareUpdate.from || '-'}</TableCell>
        <TableCell>{firmwareUpdate.to || '-'}</TableCell>
        <TableCell>{firmwareUpdate.type}</TableCell>
        <TableCell>{firmwareUpdate.priority}</TableCell>
        <TableCell>{firmwareUpdate.firmware.target}</TableCell>
        <TableCell>{firmwareUpdate.firmware.version}</TableCell>
        <TableCell>{firmwareUpdate.rule}</TableCell>
        <TableCell>
          {format(parseISO(firmwareUpdate.createdAt), 'PPP')}
        </TableCell>
        <TableCell align="right">
          <ModeEditOutlineOutlinedIcon
            onClick={() => setOpenFirmwareUpdateModal(true)}
            fontSize="small"
            sx={{ mr: '0.6rem', cursor: 'pointer' }}
          />
          <DeleteOutlinedIcon
            onClick={() => onDeleteHandler(firmwareUpdate.id)}
            fontSize="small"
            sx={{ mr: '0.6rem', cursor: 'pointer' }}
          />
          <FirmwareUpdateModal
            openFirmwareUpdateModal={openFirmwareUpdateModal}
            setOpenFirmwareUpdateModal={setOpenFirmwareUpdateModal}
          >
            <UpdatefirmwareUpdate
              setOpenFirmwareUpdateModal={setOpenFirmwareUpdateModal}
              firmwareUpdate={firmwareUpdate}
            />
          </FirmwareUpdateModal>
        </TableCell>
      </TableRow>
    </>
  );
};

export default FirmwareUpdateItem;
