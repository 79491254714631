import { TableCell, TableRow } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { FC, useEffect, useState } from 'react';
import FirmwareModal from '../modals/firmware.modal';
import { useDeleteFirmwareMutation } from '../../redux/api/firmwareApi';
import { toast } from 'react-toastify';
import UpdateFirmware from './update-firmware';
import { IFirmware } from '../../redux/api/types';
import { format, parseISO } from 'date-fns';
import * as React from 'react';

interface IFirmwareItemProps {
  firmware: IFirmware;
}

const FirmwareItem: FC<IFirmwareItemProps> = ({ firmware }: any) => {
  const [openFirmwareModal, setOpenFirmwareModal] = useState(false);
  const [deleteFirmware, { isLoading, error, isSuccess, isError }] =
    useDeleteFirmwareMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success('firmware deleted successfully');
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: 'top-right',
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: 'top-right',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onDeleteHandler = (id: number) => {
    if (window.confirm('Are you sure')) {
      deleteFirmware(id);
    }
  };

  return (
    <>
      <TableRow key={firmware.id}>
        <TableCell>{firmware.id}</TableCell>
        <TableCell>{firmware.target}</TableCell>
        <TableCell>{firmware.version}</TableCell>
        <TableCell>{firmware.description}</TableCell>
        <TableCell>{firmware.name}</TableCell>
        <TableCell>{firmware.active ? 'Да' : 'Нет'}</TableCell>
        <TableCell>{format(parseISO(firmware.createdAt), 'PPP')}</TableCell>
        <TableCell align="right">
          <ModeEditOutlineOutlinedIcon
            onClick={() => setOpenFirmwareModal(true)}
            fontSize="small"
            sx={{ mr: '0.6rem', cursor: 'pointer' }}
          />
          <DeleteOutlinedIcon
            onClick={() => onDeleteHandler(firmware.id)}
            fontSize="small"
            sx={{ mr: '0.6rem', cursor: 'pointer' }}
          />
          <FirmwareModal
            openFirmwareModal={openFirmwareModal}
            setOpenFirmwareModal={setOpenFirmwareModal}
          >
            <UpdateFirmware
              setOpenFirmwareModal={setOpenFirmwareModal}
              firmware={firmware}
            />
          </FirmwareModal>
        </TableCell>
      </TableRow>
    </>
  );
};

export default FirmwareItem;
