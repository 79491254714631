import { toast } from 'react-toastify';

export const showErrorToast = (error: any) => {
  if (Array.isArray((error as any).data.error)) {
    (error as any).data.error.forEach((el: any) =>
      toast.error(el.message, {
        position: 'top-right',
      }),
    );
  } else if (typeof (error as any).data.message === 'object') {
    for (const key in (error as any).data.message) {
      toast.error((error as any).data.message[key], {
        position: 'top-right',
      });
    };
  } else {
    toast.error((error as any).data.message, {
      position: 'top-right',
    });
  }
};

export const showSuccessToast = (value: string): void => {
  toast.success(value);
};
