import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFirmware } from '../api/types';

interface IFirmwareState {
  firmware: IFirmware | null;
}

const initialState: IFirmwareState = {
  firmware: null,
};

export const firmwareSlice = createSlice({
  initialState,
  name: 'firmwareSlice',
  reducers: {
    firmwareState: (state, action: PayloadAction<IFirmware>) => {
      state.firmware = action.payload;
    },
  },
});

export default firmwareSlice.reducer;

export const { firmwareState } = firmwareSlice.actions;
