import { FC, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid } from '@mui/material';
import {
  FormContainer,
  SwitchElement,
  TextFieldElement,
} from 'react-hook-form-mui';
import { boolean, object, string } from 'zod';

const firmwareSchema = object({
  target: string().min(1, 'Обязательное поле'),
  version: string().min(1, 'Обязательное поле'),
  description: string().optional(),
  name: string().min(1, 'Обязательное поле'),
  active: boolean(),
});

const emptyValue = {
  target: '',
  version: '',
  description: undefined,
  name: '',
  active: true,
};

export const FirmwareForm: FC<any> = ({ handleSubmit, defaultValues }: any) => {
  return (
    <FormContainer
      defaultValues={defaultValues || emptyValue}
      resolver={zodResolver(firmwareSchema)}
      onSuccess={values => handleSubmit(values)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldElement
            name="target"
            type="string"
            size="small"
            label="Таргет"
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldElement
            name="version"
            type="string"
            size="small"
            label="Версия"
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldElement
            name="description"
            type="string"
            size="small"
            label="Описание"
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldElement
            name="name"
            type="string"
            size="small"
            label="Название файла"
          />
        </Grid>

        <SwitchElement
          label="Активный"
          name="active"
        />

        <Grid item xs={12}>
          <Button type="submit" variant="contained" fullWidth>
            Сохранить
          </Button>
        </Grid>
      </Grid>
    </FormContainer>
  );
};
